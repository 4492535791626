<template>
  <div class="wrap">
    <Title
      pageTitle="TAILORED T-SHIRTS"
      pageDiscrption="テイラーTシャツ"
      color="var(--color-tailor-light)"
    />
    <div class="container">
      <div class="discription">
        <p class="price">
          T-SHIRTS: 14,000円（税込）<br />
          LONG T-SHIRTS: 16,000円（税込）
          <!-- LONG T-SHIRTS: <span style="text-decoration: line-through; font-size: 1.3rem; padding-right: 1rem;">16,000円</span>14,000円（税込） -->
        </p>
        <button @click="toRepeat">追加注文のお客様はこちらから</button>
        <h2 class="heading-3">
          採寸データからオリジナルのTシャツを<br class="spOnly" />お仕立てします
        </h2>
        <p>
          インターネット上で完結できるフルオーダーメイドTシャツです。<br
            class="pcOnly"
          />サンプルTシャツを製作し、ご試着・確認後に納品となりますので、仕上がりが心配な方もご安心ください。<br />また追加購入時に再度修正するお直しサービスも無料でご利用いただけます。
        </p>
      </div>

      <form
        method="post"
        action="./php/tailor.php"
        class="form"
        @submit="toPayment()"
      >
        <div class="form-content st-body">
          <h3 class="form-content__title heading-3">ボディタイプ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ボディタイプ"
                id="tshirt"
                value="T-SHIRTS"
                checked=""
              />
              <label class="tshirt" for="tshirt"
                ><span class="name">T-SHIRTS</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ボディタイプ"
                id="long"
                value="LONG T-SHIRTS"
              />
              <label class="long-tshirt" for="long"
                ><span class="name">LONG T-SHIRTS</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line1"></div>

        <div class="form-content st-color">
          <h3 class="form-content__title heading-3">カラー</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="カラー"
                id="white"
                value="WHITE"
                checked=""
              />
              <label class="color-white" for="white"
                ><span class="name">WHITE</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="カラー" id="gray" value="GRAY" />
              <label class="color-gray" for="gray"
                ><span class="name">GRAY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="カラー" id="navy" value="NAVY" />
              <label class="color-navy" for="navy"
                ><span class="name">NAVY</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="カラー" id="black" value="BLACK" />
              <label class="color-black" for="black"
                ><span class="name">BLACK</span></label
              >
            </div>
          </div>
          <div class="note">
            生地の種類によってグレーとネイビーは色味が異なります。詳細は<span
              @click="toFabric"
              >「生地・カラー」</span
            >をご覧ください
          </div>
        </div>

        <div class="next line2"></div>

        <div class="form-content st-fabric">
          <h3 class="form-content__title heading-3">生地</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input type="radio" name="生地" id="light" value="LIGHT" />
              <label class="fabric" for="light">
                <div class="fabric__title name">
                  LIGHT<span class="oz">4.4oz</span>
                </div>
                <div class="fabric__feature">
                  目が細かくハリとコシがある薄手生地です。程良い透け感と薄地ならではの軽量感で暑い時期に快適に着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="生地"
                id="medium"
                value="MEDIUM"
                checked=""
              />
              <label class="fabric" for="medium">
                <div class="fabric__title name">
                  MEDIUM<span class="oz">6.0oz</span>
                </div>
                <div class="fabric__feature">
                  コーマ糸を使用した高品質でスタンダードな厚みの生地です。クセがないので場面や着こなしを気にせずにご着用いただけます
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="生地" id="heavy" value="HEAVY" />
              <label class="fabric" for="heavy">
                <div class="fabric__title name">
                  HEAVY<span class="oz">7.6oz</span>
                </div>
                <div class="fabric__feature">
                  重量感のある厚手生地です。間隔を詰めて生地を編んでいるので、耐久性に優れ、厚手により肌透けがしにくいのが特徴です
                </div>
              </label>
            </div>

            <div class="form-content__select--item">
              <input type="radio" name="生地" id="smooth" value="SMOOTH" />
              <label class="fabric" for="smooth">
                <div class="fabric__title name">
                  SMOOTH<span class="oz">5.5oz</span>
                </div>
                <div class="fabric__feature">
                  ソフトな風合いと適度な光沢が特徴的な生地です。光沢加工を施すことで、高級感と品のある仕上がりを実現しています
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="生地" id="stretch" value="STRETCH" />
              <label class="fabric" for="stretch">
                <div class="fabric__title name">
                  STRETCH<span class="oz">5.9oz</span>
                </div>
                <div class="fabric__feature">
                  縦横に伸縮する非常に柔軟性のある生地です。フィットサイズで作っても圧迫感や動きにくさを感じることはありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="生地"
                id="mira-wave"
                value="MIRA WAVE"
              />
              <label class="fabric" for="mira-wave">
                <div class="fabric__title name">
                  MIRA WAVE<span class="oz">8.8oz</span>
                </div>
                <div class="fabric__feature">
                  抗菌処理を施し、速乾・接触冷感・UVカットを兼ね備えた生地です。シワがつかず、洗濯による縮みもほとんどありません
                </div>
              </label>
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="生地" id="coolmax" value="COOLMAX" />
              <label class="fabric" for="coolmax">
                <div class="fabric__title name">
                  COOLMAX<span class="oz">4.6oz</span>
                </div>
                <div class="fabric__feature">
                  吸収速乾に優れた素材です。涼しく快適な着心地がありますので、スポーツはもちろんアクティブな場面におすすめです
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="next line3"></div>

        <div class="form-content st-neck">
          <h3 class="form-content__title heading-3">ネック</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネック"
                id="O"
                value="O-NECK"
                checked=""
              />
              <label class="neck-o neck" for="O"
                ><span class="name">O-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="ネック" id="O-Deep" value="O-DEEP" />
              <label class="neck-o-deep neck" for="O-Deep"
                ><span class="name">O-DEEP</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="ネック" id="V" value="V-NECK" />
              <label class="neck-v neck" for="V"
                ><span class="name">V-NECK</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input type="radio" name="ネック" id="V-Deep" value="V-DEEP" />
              <label class="neck-v-deep neck" for="V-Deep"
                ><span class="name">V-DEEP</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line4"></div>

        <div class="form-content st-necklib">
          <h3 class="form-content__title heading-3">ネックリブタイプ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネックリブタイプ"
                id="casual"
                value="CASUAL"
                checked=""
              />
              <label class="casual lib" for="casual"
                ><span class="name">CASUAL</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネックリブタイプ"
                id="formal"
                value="FORMAL"
              />
              <label class="formal lib" for="formal"
                ><span class="name">FORMAL</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line5"></div>

        <div class="form-content st-neckwide">
          <h3 class="form-content__title heading-3">ネックリブ幅</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネックリブ幅"
                id="lib-slim"
                value="SLIM"
              />
              <label class="slim lib-wide" for="lib-slim"
                ><span class="name">SLIM</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネックリブ幅"
                id="lib-medium"
                value="MEDIUM"
                checked=""
              />
              <label class="medium lib-wide" for="lib-medium"
                ><span class="name">MEDIUM</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="ネックリブ幅"
                id="lib-wide"
                value="WIDE"
              />
              <label class="wide lib-wide" for="lib-wide"
                ><span class="name">WIDE</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line6"></div>

        <div class="form-content st-sleeve">
          <h3 class="form-content__title heading-3">袖口リブ</h3>
          <div class="form-content__select">
            <div class="form-content__select--item">
              <input
                type="radio"
                name="袖口リブ"
                id="noLib"
                value="リブなし"
                checked=""
              />
              <label class="noLib sleeve-lib" for="noLib"
                ><span class="name">リブなし</span></label
              >
            </div>
            <div class="form-content__select--item">
              <input
                type="radio"
                name="袖口リブ"
                id="addLib"
                value="リブ付き"
              />
              <label class="addLib sleeve-lib" for="addLib"
                ><span class="name">リブ付き</span></label
              >
            </div>
          </div>
        </div>

        <div class="next line7"></div>

        <div class="form-content st-customer">
          <h3 class="form-content__title heading-3">お客様情報</h3>
          <div class="info-btn">
            <input
              @change="byBodySize"
              type="radio"
              name="製作方法"
              value="ボディサイズから製作する"
              id="body-size"
              checked=""
            />
            <label class="info-btn__item" for="body-size"
              ><span class="name">ボディサイズから製作する</span></label
            >
            <input
              @change="byTshirtSize"
              type="radio"
              name="製作方法"
              value="Tシャツサイズから製作する"
              id="tshirt-size"
            />
            <label class="info-btn__item" for="tshirt-size"
              ><span class="name">Tシャツサイズから製作する</span></label
            >
          </div>

          <div class="customer-input">
            <div v-if="isActive" class="body-size measure-data">
              <div class="measure-data__body">
                <div class="input-item">
                  <label for="height">身長</label
                  ><input
                    type="number"
                    name="身長"
                    step="0.1"
                    id="height"
                    placeholder="身長を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="chest">胸囲</label
                  ><input
                    type="number"
                    name="胸囲"
                    step="0.1"
                    id="chest"
                    placeholder="太い部分で計測してください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="waist">胴囲</label
                  ><input
                    type="number"
                    name="胴囲"
                    step="0.1"
                    id="waist"
                    placeholder="へそ付近で計測してください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">腕回り</label
                  ><input
                    type="number"
                    name="腕回り"
                    step="0.1"
                    id="arm"
                    placeholder="太い部分で計測してください"
                    required
                  /><span>cm</span>
                </div>
              </div>
              <div class="measure-data__shoulder">
                <div class="measure-data__shoulder--detail">
                  <div class="measure-data__shoulder--detail--title">肩幅</div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩幅"
                      id="shoulder-narrow"
                      value="狭め"
                    />
                    <label for="shoulder-narrow"
                      ><span class="name">狭め</span></label
                    >
                  </div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩幅"
                      id="shoulder-normal"
                      value="普通"
                      checked=""
                    />
                    <label for="shoulder-normal"
                      ><span class="name">普通</span></label
                    >
                  </div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩幅"
                      id="shoulder-wide"
                      value="広め"
                    />
                    <label for="shoulder-wide"
                      ><span class="name">広め</span></label
                    >
                  </div>
                </div>
                <div class="measure-data__shoulder--detail">
                  <div class="measure-data__shoulder--detail--title">
                    肩傾斜
                  </div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩傾斜"
                      id="shoulder-nade"
                      value="なで肩"
                    />
                    <label for="shoulder-nade"
                      ><span class="name">なで肩</span></label
                    >
                  </div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩傾斜"
                      id="shoulder-normal2"
                      value="普通"
                      checked=""
                    />
                    <label for="shoulder-normal2"
                      ><span class="name">普通</span></label
                    >
                  </div>
                  <div class="measure-data__shoulder--detail--btn">
                    <input
                      type="radio"
                      name="肩傾斜"
                      id="shoulder-ikari"
                      value="いかり肩"
                    />
                    <label for="shoulder-ikari"
                      ><span class="name">いかり肩</span></label
                    >
                  </div>
                </div>
              </div>
              <div class="measure-data__style">
                <div class="measure-data__style--title">サイズ感</div>
                <div class="measure-data__style--detail">
                  <div class="measure-data__style--detail--btn">
                    <input
                      type="radio"
                      name="サイズ感"
                      id="style-fit"
                      value="タイト"
                    />
                    <label for="style-fit"
                      ><span class="name">タイト</span></label
                    >
                  </div>
                  <div class="measure-data__style--detail--btn">
                    <input
                      type="radio"
                      name="サイズ感"
                      id="style-normal"
                      value="普通"
                      checked=""
                    />
                    <label for="style-normal"
                      ><span class="name">普通</span></label
                    >
                  </div>
                  <div class="measure-data__style--detail--btn">
                    <input
                      type="radio"
                      name="サイズ感"
                      id="style-loose"
                      value="ルーズ"
                    />
                    <label for="style-loose"
                      ><span class="name">ルーズ</span></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="tshirt-size measure-data">
              <div class="measure-data__tshirt">
                <div class="measuring-img">
                  <img src="../assets/img/measuring.svg" alt="" />
                </div>
                <div class="input-item">
                  <label for="height">着丈</label
                  ><input
                    type="number"
                    name="着丈"
                    step="0.1"
                    id="height"
                    placeholder="着丈を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="chest">着幅</label
                  ><input
                    type="number"
                    name="着幅"
                    step="0.1"
                    id="chest"
                    placeholder="着幅を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="waist">腰幅</label
                  ><input
                    type="number"
                    name="腰幅"
                    step="0.1"
                    id="waist"
                    placeholder="腰幅を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">裾幅</label
                  ><input
                    type="number"
                    name="裾幅"
                    step="0.1"
                    id="arm"
                    placeholder="裾幅を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">肩幅</label
                  ><input
                    type="number"
                    name="肩幅"
                    step="0.1"
                    id="arm"
                    placeholder="肩幅を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">脇高</label
                  ><input
                    type="number"
                    name="脇高"
                    step="0.1"
                    id="arm"
                    placeholder="脇高を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">袖丈</label
                  ><input
                    type="number"
                    name="袖丈"
                    step="0.1"
                    id="arm"
                    placeholder="袖丈を記入ください"
                    required
                  /><span>cm</span>
                </div>
                <div class="input-item">
                  <label for="arm">袖幅</label
                  ><input
                    type="number"
                    name="袖幅"
                    step="0.1"
                    id="arm"
                    placeholder="袖幅を記入ください"
                    required
                  /><span>cm</span>
                </div>
              </div>
            </div>

            <div class="customer-data">
              <div class="input-item">
                <label for="height">お名前</label
                ><input
                  type="text"
                  name="お名前"
                  placeholder="フルネームで記入ください"
                  required
                />
              </div>
              <div class="input-item">
                <label for="email">メールアドレス</label
                ><input
                  type="email"
                  name="メールアドレス"
                  placeholder="正確にご記入ください"
                  required
                />
              </div>
              <div class="input-comment">
                <label class="input-comment__title" for="comment"
                  >コメント</label
                >
                <textarea
                  id="comment"
                  name="コメント"
                  class="input-comment__box"
                  placeholder="その他ご要望などありましたらご記入ください"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="submit">
          <button type="submit">注文する</button>
          <div class="note">
            注文後に決済ページが表示されますので<br
              class="spOnly"
            />決済をお願い致します。
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import Title from '../components/Title.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { onMounted } from '@vue/runtime-core'

export default {
  components: { Title },
  setup() {
    onMounted(() => {
      gsap.registerPlugin(ScrollTrigger)

      formContent('.st-body')
      formContent('.st-color')
      formContent('.st-fabric')
      formContent('.st-neck')
      formContent('.st-necklib')
      formContent('.st-neckwide')
      formContent('.st-sleeve')
      formContent('.st-customer')
      nextLine('.line1')
      nextLine('.line2')
      nextLine('.line3')
      nextLine('.line4')
      nextLine('.line5')
      nextLine('.line6')
      nextLine('.line7')
    })

    const formContent = (item) => {
      gsap.fromTo(
        item,
        { y: 150, autoAlpha: 0 },
        {
          y: 0,
          autoAlpha: 1,
          duration: 0.3,
          delay: 0.3,
          scrollTrigger: { trigger: item, start: '20% bottom' },
        }
      )
    }
    const nextLine = (item) => {
      gsap.to(item, {
        height: 70,
        duration: 0.3,
        scrollTrigger: { trigger: item, start: 'bottom 70%' },
      })
    }

    const isActive = ref(true)
    const byBodySize = () => {
      isActive.value = true
    }
    const byTshirtSize = () => {
      isActive.value = false
    }
    const router = useRouter()
    const toPayment = () => {
      router.push({ name: 'Payment' })
    }
    const toRepeat = () => {
      router.push({ name: 'Repeat' })
    }
    const toFabric = () => {
      router.push({ name: 'Fabrics' })
    }

    return { isActive, byBodySize, byTshirtSize, toPayment, toRepeat, toFabric }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url('../assets/img/tailor-bg.jpg');
  background-size: 150%;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-size: 350%;
  }
}
.discription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;

  & p {
    text-align: center;
    line-height: 2;
  }

  & .price {
    font-family: 'objektiv-mk1', sans-serif;
  }

  & button {
    margin: 5rem 0 8rem;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    color: var(--color-light);
    padding: 1.2rem 4rem;
    border: 1px solid var(--color-medium);
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      border: 1px solid var(--color-light);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  & .heading-3 {
    margin-bottom: 3.5rem;
    font-size: 18px;
    line-height: 1.8;

    @media screen and (max-width: 425px) {
      text-align: center;
    }

    & + p {
      @media screen and (max-width: 425px) {
        text-align: left;
      }
    }
  }
}

.form-content {
  width: 100%;
  background-color: #000;
  padding: 4rem;
  text-align: center;
  border-radius: 10px;

  @media screen and (max-width: 425px) {
    padding: 2rem;
  }

  &__title {
    margin-bottom: 4rem;

    @media screen and (max-width: 425px) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }
  }

  &__select {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 2rem;

    &--item {
      display: flex;
      flex-direction: column;

      & input {
        display: none;
      }

      & input:checked + label {
        border: 3px solid #fff;
        opacity: 1;
      }

      & label {
        width: 100%;
        min-height: 100%;
        height: 350px;
        border: 3px solid #000;
        border-radius: 10px;
        background-size: cover;
        background-color: #000;
        background-position: center;
        transition: border 0.3s ease-out;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 2rem;
        font-family: 'objektiv-mk1', sans-serif;
        opacity: 0.8;

        & .name {
          background-color: var(--color-heavy);
          display: inline-block;
          padding: 5px 15px;
          font-size: 11px;
          font-family: 'objektiv-mk1', sans-serif;
          border-radius: 100px;
        }

        &.tshirt {
          background-image: url('../assets/img/bodyType-Tshirt.jpg');
        }
        &.long-tshirt {
          background-image: url('../assets/img/bodyType-LongTshirt.jpg');
        }
        &.color-white {
          background-image: url('../assets/img/white.jpg');
        }
        &.color-gray {
          background-image: url('../assets/img/gray.jpg');
        }
        &.color-navy {
          background-image: url('../assets/img/navy.jpg');
        }
        &.color-black {
          background-image: url('../assets/img/black.jpg');
        }
        &.neck-o {
          background-image: url('../assets/img/neck-O.jpg');
        }
        &.neck-v {
          background-image: url('../assets/img/neck-V.jpg');
        }
        &.neck-o-deep {
          background-image: url('../assets/img/neck-O-Deep.jpg');
        }
        &.neck-v-deep {
          background-image: url('../assets/img/neck-V-Deep.jpg');
        }
        &.casual {
          background-image: url('../assets/img/lib-casual.jpg');
        }
        &.formal {
          background-image: url('../assets/img/lib-formal.jpg');
        }
        &.slim {
          background-image: url('../assets/img/lib-slim.jpg');
        }
        &.medium {
          background-image: url('../assets/img/lib-medium.jpg');
        }
        &.wide {
          background-image: url('../assets/img/lib-wide.jpg');
        }
        &.noLib {
          background-image: url('../assets/img/lib-fold.jpg');
        }
        &.addLib {
          background-image: url('../assets/img/lib-attach.jpg');
        }
      }

      & .fabric {
        width: 100%;
        height: auto;
        padding: 3rem 2rem;
        justify-content: center;
        align-items: center;
        background-color: var(--color-dark);

        &__title {
          margin-bottom: 1rem;
          font-family: 'objektiv-mk1', sans-serif;
          font-weight: 700;
          display: flex;
          align-items: center;
          font-size: 16px;
          letter-spacing: 0.2rem;

          & .oz {
            font-size: 11px;
            margin-left: 5px;
          }
        }

        &__feature {
          font-size: 12px;
          line-height: 1.6;
          text-align: left;
        }
      }

      & .neck {
        height: 250px;
      }

      & .lib {
        @media screen and (max-width: 425px) {
          height: 250px;
        }
      }

      & .lib-wide {
        height: 250px;
      }

      & .sleeve-lib {
        height: 250px;
      }
    }
  }
}

.note {
  margin-top: 2rem;
  font-size: 12px;

  & span {
    font-size: 12px;
    cursor: pointer;
  }
}

.next {
  width: 1px;
  height: 0;
  background-color: var(--color-dark);
  margin: 10px auto;
}

.info-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }

  & input {
    display: none;
  }

  & input:checked + label {
    border: 1px solid var(--color-light);
    background-color: #1a1a1a;
  }

  &__item {
    width: 300px;
    margin: 0 10px;
    color: var(--color-light);
    padding: 15px 20px;
    border: 1px solid #555;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    transition: border 0.3s ease-out;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__item:hover {
    border: 1px solid #aaa;
  }
}

.customer-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 8rem;
}

.measure-data {
  text-align: left;

  &__shoulder {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;

    &--detail {
      width: 45%;

      &--title {
        margin-bottom: 2rem;
        padding-left: 2rem;
      }

      &--btn {
        & label {
          display: block;
          color: var(--color-light);
          width: 100%;
          margin: 1.5rem 0;
          padding: 15px 20px;
          border: 1px solid #555;
          border-radius: 100px;
          font-size: 1.5rem;
          text-align: center;
          font-weight: 300;
          cursor: pointer;
          transition: border 0.3s ease-out;
        }

        & input {
          display: none;
        }

        & input:checked + label {
          border: 1px solid var(--color-light);
          background-color: #1a1a1a;
        }
      }
    }
  }
}

.input-item {
  border-bottom: 1px solid var(--color-dark);
  display: flex;
  align-items: center;

  & label {
    display: inline-block;
    width: 100px;
    padding-left: 20px;

    @media screen and (max-width: 425px) {
      padding-left: 10px;
      width: 60px;
    }
  }

  & input {
    width: calc(100% - 100px);
    background-color: transparent;
    border: none;
    padding: 2rem 2rem 1.7rem;
    color: var(--color-light);
    font-size: 16px;

    @media screen and (max-width: 425px) {
      font-size: 15px;
    }

    &:focus {
      outline: none;
    }
  }
}

.measure-data__style {
  margin-top: 3rem;

  &--title {
    margin-left: 2rem;
    margin-bottom: 0.5rem;
  }

  &--detail {
    display: flex;
    justify-content: space-between;

    &--btn {
      width: 30%;

      & label {
        display: block;
        color: var(--color-light);
        margin: 1.5rem 0;
        padding: 15px 20px;
        border: 1px solid #555;
        border-radius: 100px;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        cursor: pointer;
        transition: border 0.3s ease-out;
      }

      & input {
        display: none;
      }

      & input:checked + label {
        border: 1px solid var(--color-light);
        background-color: #1a1a1a;
      }
    }
  }
}

.measuring-img {
  width: 100%;
  text-align: center;

  & img {
    width: 70%;
  }
}

.customer-data {
  display: flex;
  flex-direction: column;

  & .input-item {
    & label {
      display: inline-block;
      width: 140px;
      padding-left: 10px;
      text-align: left;
    }

    & input {
      width: calc(100% - 100px);
      background-color: transparent;
      border: none;
      padding: 2rem 2rem 1.7rem;
      color: var(--color-light);
      font-size: 15px;

      &:focus {
        outline: none;
      }
    }
  }
}

.input-comment {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;

  &__title {
    text-align: left;
    margin-left: 10px;
  }

  &__box {
    margin-top: 1rem;
    height: 250px;
    background-color: transparent;
    border: 1px solid var(--color-dark);
    color: var(--color-light);
    padding: 2rem;
    font-family: inherit;
    font-size: 15px;
  }

  &__box:focus {
    outline: none;
  }
}

.submit {
  text-align: center;
  margin: 6rem 0 3rem;

  & button {
    color: var(--color-light);
    margin-bottom: 3rem;
    width: 300px;
    background-color: var(--color-tailor-light);
    padding: 15px 20px;
    border: none;
    border-radius: 100px;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: var(--color-tailor-dark);
    }
  }
}
</style>
